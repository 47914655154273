$text: #aaaaaa;
$button: #001f3f;
$modal: white;
$modal-input: #ced6db;
$modal-input-border: #777c80;
$modal-text: black;
$tab-color: teal;
$background: rgb(33, 37, 41);
$background-stripped: #2c3034;
$button-text: white;
$active: #3d9970;
$hover: #777c80;
$request-border: #3d9970;
