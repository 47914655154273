@use "resources/styles/colors";

.vehicle__view {
  margin-top: 2rem;
  width: 90%;
}

.one__vehicle {
  margin-top: 5rem;
  max-height: 80vh;
  overflow-y: scroll;
  padding-bottom: 1rem;
}

.vehicle {
  border: 2px solid colors.$modal-input-border;
  padding: 2rem;
  margin: auto auto;
  &__body {
    margin-right: -12px;
    max-height: 80vh;
    overflow-y: scroll;
    padding-bottom: 1rem;
  }

  &__buttons {
    margin-top: 5rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    cursor: pointer;
    color: white;
    border: 1px solid colors.$text;
    border-bottom: none;
    font-size: 1.5rem;
    padding: 0.3rem;
    background-color: colors.$background;

    &:last-of-type {
      border-bottom: 1px solid colors.$text;
    }

    &.header {
      padding: 2rem 0;
      border: 2px solid colors.$text;
    }

    &.stripped {
      background-color: colors.$background-stripped;
    }

    &:hover {
      & > div {
        background-color: colors.$hover;
        cursor: pointer;
      }
    }
  }
}
