@use "resources/styles/colors";

body {
  background-color: #181a1b;
  overflow-x: hidden;
  color: #dddddd;
}
.main-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.main-content {
  max-width: 83vw;
  max-height: 100vh;
  width: 100%;
  overflow: scroll;
  position: relative;
  scrollbar-color: grey black;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  & modal {
    background-color: transparent;
  }
}

@keyframes appSidebarSubMenuSlideInRight {
  to {
    transform: translate3d(0, 0, 0);
  }
}

.sidebar {
  min-height: 100vh;
  height: 100vh;
  width: 17vw;
  min-width: 17vw;
  background-color: #181a1b;
  overflow-y: scroll;
  scrollbar-color: grey black;
  overflow-x: hidden;
  &__title {
    margin-top: 1rem;
  }

  &__icon {
    overflow: hidden;
    padding: 2rem;
    margin-top: 1rem;
    width: 100%;

    img {
      margin: 0 auto;
      max-width: 70%;
      height: auto;
      display: block;
    }
  }

  &__navbar {
    margin-top: 1rem;
    width: 17vw;
  }

  .dropdown-button {
    &.link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border: none;
      margin: 0;
      background: inherit;
    }
  }

  .dropdown-list {
    list-style: none;
    overflow: hidden;
    display: block;

    &.sublist {
      .link {
        margin: 0;
        padding: 0;
        text-align: left;
        padding-left: 4rem;
        margin-bottom: 1rem;
      }
    }

    & > li {
      animation: appSidebarSubMenuSlideInRight 0.3s both
        cubic-bezier(0.7, 0, 0.3, 1);
      transform: translate3d(-100%, 0, 0);
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 50ms;
        }
      }
    }

    &.hidden {
      display: none;
      & > li {
        animation: none;
      }
    }
  }

  .link {
    text-decoration: none;
    padding: 2rem;
    font-size: 1rem;
    display: block;
    cursor: pointer;
    color: #dddddd;
    color: white;
    text-align: left;
    transition: color 0.2s ease-in-out;
    letter-spacing: 0.1rem;

    &.active {
      color: colors.$active;
    }
    .left {
      display: flex;
      align-items: center;
      > svg {
        margin-right: 0.8rem;
      }
    }

    &:hover {
      color: colors.$active;
    }
  }
}

.load-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
