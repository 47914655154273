@use "resources/styles/colors";
*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.table {
  &__button {
    font-size: 1.25rem;
    color: #3d9970;
    cursor: pointer;
    margin: 0.2rem;
  }

  &__hidden {
    display: none;
  }

  &__shown {
    display: table-row;
  }
}

.request {
  padding: 2rem;
  width: 100%;
  font-size: 1rem;
  display: grid;
  grid-template-columns: repeat(9, 1fr);

  & .edit {
    color: blue;
    cursor: pointer;
    display: flex;

    &:hover {
      color: #3d9970;
    }
  }

  &-wrapper {
    border: 3px solid #3d9970;
    border-bottom: none;
    transition: all 0.4s ease-in-out;
    transition: max-height 0.4s ease-in-out;
    max-height: 600px;
    overflow-y: scroll;

    &.hidden {
      overflow-y: scroll;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    font-size: 1.5rem;
    grid-gap: 1rem;
    padding: 2rem;
    &-wrapper {
      border: 3px solid #3d9970;
      border-top: none;
      padding-bottom: 1rem;
      margin-bottom: 4rem;
    }
  }

  &__data {
    margin-right: 2.5rem;
    font-size: 1.5rem;
    & > input {
      height: 2rem;
    }
    input {
      font-size: 1.5rem;
      margin: 1rem;
    }
    :first-child {
      padding-right: 1.5rem;
    }
  }

  &--footer {
    display: flex;
    justify-content: space-evenly;
  }

  &--date {
    display: flex;
    font-size: 2rem;
    justify-content: space-around;
    width: 100%;
  }

  &--phi {
    display: flex;
    font-size: 2rem;
    justify-content: space-around;
    width: 100%;
    width: 100%;
  }

  button {
    color: black;
  }

  &__header {
    display: contents;
    font-size: 1.3rem;
    font-weight: 700;

    & > div {
      padding: 0 1rem;
    }
  }

  &__entry {
    display: contents;

    &.consumable {
      & > div {
        color: rgba(109, 175, 79, 1);
      }
    }
  }

  & button {
    color: black;
  }
}

.parts-recieved {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: repeat(7, 1fr) 0.2fr;
  margin: 1rem 4rem;
  transition: max-height 0.4s ease-in-out, margin 0.4s 0.4s;
  overflow: hidden;
  max-height: 500px;
  overflow-y: scroll;

  &.hidden {
    margin: 0 4rem;
    overflow-y: scroll;
  }

  &__header {
    display: contents;
    > div {
      vertical-align: center;
      font-weight: 700;
      font-size: 1.3rem;
      margin-left: 1rem;
      padding: 1rem 0;
    }
  }

  &__row {
    > div {
      display: flex;
      justify-content: center;
      align-content: center;
      flex-direction: column;
    }
  }
}

.grid-border-line {
  grid-column: 1 / -1;
  height: 0;
  border-top: 3px solid #3d9970;
  margin: 1rem 0;
}

.hidden {
  max-height: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border-bottom: none;
}

.requests__find {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 3rem 1rem;
  .input {
    text-align: left;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;
    & > label {
      margin-bottom: 1rem;
    }
    & > input {
      background-color: colors.$modal-input;
      font-size: 1.5rem;
      height: 2rem;
      border: 1px solid colors.$modal-input-border;
      padding-left: 0.75rem;
    }
    & > select {
      background-color: colors.$modal-input;
      font-size: 1.5rem;
      height: 2rem;
      border: 1px solid colors.$modal-input-border;
      padding-left: 0.75rem;
      width: 100%;
      border-radius: none;
    }
  }
  form {
    background-color: colors.$background;
    display: grid;
    align-content: space-between;
    border: 1px solid colors.$modal-input-border;
    width: 100%;
    height: 100%;
    padding: 1rem;
  }

  .input__bottom {
    grid-column: -1/1;
  }

  .requests--byDate {
    grid-template-columns: 1fr 1fr;
    h1 {
      grid-column: -1/1;
    }
  }
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
