@use "resources/styles/colors";

.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  height: 100%;
  z-index: 2;
  overflow-y: scroll;

  &__content {
    border: 1px solid white;
    position: absolute;
    background-color: colors.$modal;
    color: colors.$modal-text;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2rem;
    border-radius: 15px;

    &.loading {
      margin: 0 auto;
      background-color: transparent;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__confirmation {
    color: red;
    margin: 2rem;
    font-size: 2rem;
  }

  &__close {
    cursor: pointer;
  }

  &__input {
    text-align: left;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 1rem;
    & > label {
      margin-bottom: 1rem;
    }
    & > input {
      background-color: colors.$modal-input;
      font-size: 1.5rem;
      height: 2rem;
      border: 1px solid colors.$modal-input-border;
      padding-left: 0.75rem;
    }
    & > select {
      background-color: colors.$modal-input;
      font-size: 1.5rem;
      height: 2rem;
      border: 1px solid colors.$modal-input-border;
      padding-left: 0.75rem;
      width: 100%;
      border-radius: none;
    }
  }
}
