.requests {
  width: 100;
  font-size: 1.5rem;
  margin: 0 auto;
  &--byDate {
    width: 50%;
    display: block;
    margin: 0 auto;
  }

  &--byPhi {
    width: 50%;
    display: block;
  }

  input {
    margin: 0.5rem 1rem;
    font-size: 2rem;
  }
  button {
    color: black;
  }
}
