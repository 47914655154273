@use "resources/styles/_mainLayout";
@use "resources/styles/_modal";
@use "resources/styles/_protocol_table_structure";
@use "resources/styles/_request_table_structure";
@use "resources/styles/_request";
@use "resources/styles/_tab_table_structure";
@use "resources/styles/_tab";
@use "resources/styles/_vehicle";
@use "resources/styles/getRequests";
@use "resources/styles/colors";
@use "resources/styles/button";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.App {
  text-align: center;
  background-color: black;
  color: colors.$text;
}

.App-link {
  color: #61dafb;
}
