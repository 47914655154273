@use "resources/styles/colors";
button {
  padding: 0.75rem 2rem;
  margin: 1rem;
  background-color: colors.$button;
  border-radius: 5px;
  color: colors.$button-text;
  font-weight: 700;
  letter-spacing: 0.1rem;
}
