* {
  box-sizing: border-box;
}

.protocol__table {
  max-width: 290mm;

  .table__body td {
    border: 1px solid #33cccc;
    height: 14px;
    text-align: center;
  }
}

.protocol__table * {
  font-family: "DejaVu sans";
  font-size: x-small;
}

a.comment-indicator:hover + comment {
  background: #ffd;
  position: absolute;
  display: block;
  border: 1px solid black;
  padding: 0.5em;
}

a.comment-indicator {
  background: red;
  display: inline-block;
  border: 1px solid black;
  width: 0.5em;
  height: 0.5em;
}

comment {
  display: none;
}

input {
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: 10px;
  width: 100%;
}

.protocol__table {
  border-collapse: collapse;

  &-header td {
    font-weight: 700;
    font-size: 0.8rem;
  }
}
